﻿import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/index';
import { LoginComponent } from './login/index';
import { RegisterComponent } from './register/index';
import { ForgotPasswordComponent} from './forgotpassword/index';
import { AppdownComponent } from './appdown/appdown.component';

import { AuthGuard } from './_guards/index';


const appRoutes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },

    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },

    { path: 'forgotpassword', component: ForgotPasswordComponent },
    { path: 'appdown', component: AppdownComponent },
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
    
];

export const routing = RouterModule.forRoot(appRoutes);
