﻿import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatTabsModule } from '@angular/material/tabs';
import { CdkTableModule } from '@angular/cdk/table';

import { NgxPaginationModule } from 'ngx-pagination';

import { LoadingModule } from 'ngx-loading';

// used to create fake backend
import { fakeBackendProvider } from './_helpers/index';

import { AppComponent } from './app.component';
import { routing } from './app.routing';

import { AlertComponent } from './_directives/index';
import { AuthGuard } from './_guards/index';
import { JwtInterceptor } from './_helpers/index';
import { AlertService, AuthenticationService, UserService, OrdersService } from './_services/index';
import { HomeComponent } from './home/index';
import { LoginComponent } from './login/index';
import { RegisterComponent } from './register/index';
import { ForgotPasswordComponent } from './forgotpassword/forgotPassword.component';
import { AppdownComponent } from './appdown/appdown.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { NgxDeleteConfirmModule } from 'ngx-delete-confirm';
import { NgxConfirmBoxModule,NgxConfirmBoxService } from 'ngx-confirm-box';
import { UserIdleModule } from 'angular-user-idle';
import { FlexiBenefitService } from './_services/flexibenefit.service';
import { TwoDigitDecimaNumberDirective } from './two-digit-decima-number.directive';
import { CurrencyInputMaskDirective } from './currency-input-mask.directive';
import { ModalComponent } from './modal/modal.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        routing, MatTabsModule, BrowserAnimationsModule, CdkTableModule, NgxPaginationModule, LoadingModule, MatTooltipModule, CommonModule, 
        ToastrModule.forRoot({
            timeOut: 4000,
            positionClass: 'toast-top-full-width',
            progressAnimation: 'increasing',
        }),NgxDeleteConfirmModule.forRoot(),
        NgxConfirmBoxModule,
        MatDialogModule,
        // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
        // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes) 
        // and `ping` is 120 (2 minutes).
        UserIdleModule.forRoot({idle: 600, timeout: 300, ping: 120}),
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        LoginComponent,
        RegisterComponent,
        ForgotPasswordComponent,
        AppdownComponent,
        TwoDigitDecimaNumberDirective ,
        CurrencyInputMaskDirective ,
        ModalComponent
    ],
    providers: [
        AuthGuard,
        AlertService,
        AuthenticationService,
        UserService,
        OrdersService,
        FlexiBenefitService,
        NgxConfirmBoxService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },

        // provider used to create fake backend
        fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }