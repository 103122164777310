﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AlertService, AuthenticationService } from '../_services/index';

@Component({
    moduleId: module.id.toString(),
    templateUrl: 'appdown.component.html?v=${new Date().getTime()}'
})

export class AppdownComponent implements OnInit {
    model: any = {};
    loading = false;
    returnUrl: string;
    TermsDisplay:any='none';

    constructor(
        private route: ActivatedRoute,
        private router: Router) { }

    ngOnInit() {
        
    }
   
   
}
