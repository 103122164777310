import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { FlexiBenefits } from '../_models/flexibenefit';
import { User } from '../_models/user';


@Injectable()
export class FlexiBenefitService {

    /****** mini.luxasia.com ******/
    //PROD URL
    //url = 'http://116.12.52.170/plesk-site-preview/api.complimentarygoods.luxasia.com/api/cmg/'; 

    //UAT URL
    //url = 'http://116.12.52.170/plesk-site-preview/uatcmgapi.complimentarygoods.luxasia.com/api/cmg/';

    /****** plesk.luxasia.com ******/
    //PROD URL
    url = 'https://plesk.luxasia.com/plesk-site-preview/api.complimentarygoods.luxasia.com/api/cmg/';

    //UAT URL
    //url = 'http://115.42.209.163/plesk-site-preview/uatcmgapi.complimentarygoods.luxasia.com/api/cmg/';

    //url = 'http://localhost:9287/api/cmg/'; /* local URL*/ 

    currentUser: User;

    constructor(private http: HttpClient) {
    }

    getFlexiBenefit(customerId: number) {

        return this.http.get<any>(this.url + 'GetFlexiBenefit/' + customerId)
            .map(flexiBenefit => {
                return flexiBenefit;
            });
    }

    getFlexiBenefitValues(customerId: number) {

        return this.http.get<any>(this.url + 'GetFlexiBenefitValues/' + customerId)
            .map(fbValues => {
                return fbValues;
            });
    }

    downloadFile(fbId: number) {

        // let Options = ;
        return this.http.get<Blob>(this.url + 'DownloadFile/' + fbId, { responseType: 'blob' as 'json' })
            .map(fbfile => {
                return fbfile;
            });
    }

    // approverejectFlexiBenefit(fbid: number,approvereject: number, comments: string) {

    //     let headerOptions = new HttpHeaders({ 'Content-Type': 'application/json','cache': 'false' });
    //     return this.http.post<any>(this.url + 'ApproveRejectFlexiBenefit/' + fbid + '/' + approvereject + '/' + comments, {
    //         headers: headerOptions
    //     })
    //         .map(result => {
    //             return result;
    //         });
    // }

    approverejectFlexiBenefit(formData: FormData): Observable<any> {
        var objectToSend = JSON.stringify(formData);

        //let headerOptions = new HttpHeaders({ 'Content-Type': 'false' });

        return this.http.post<any>(this.url + 'ApproveRejectFlexiBenefit', formData)
            .map(data => {
                return data;
            });
    }

    deleteFlexiBenefit(fbid: number) {
        //return this.http.post<any>(this.url + 'DeleteFlexiBenefit/' + fbid, {
        //    headers: headerOptions
        //})
        //    .map(result => {
        //        return result;
        //    });
        return this.http.get<any>(this.url + 'DeleteFlexiBenefit/' + fbid)
            .map(result => {
                return result;
            });

    }

    //getAllFlexiBenefit(isCustom: boolean) {
    //    
    //    return this.http.get<any>(this.url + 'GetAllFlexiBenefit/'+ isCustom)
    //        .map(allFlexiBenefits => {
    //            return allFlexiBenefits;
    //        });
    //}

    getAllFlexiBenefit(countryCode: string) {
        return this.http.get<any>(this.url + 'GetAllFlexiBenefit/' + countryCode)
            .map(allFlexiBenefits => {
                return allFlexiBenefits;
            });
    }

    exportFlexiBenefit(StartDate: Date, EndDate: Date, Status: string, Id: string) {

        // return this.http.get<any>(this.url + 'ExportFlexiBenefit/'+ StartDate + '/' + EndDate)
        //     .map(allFlexiBenefits => {
        //         return allFlexiBenefits;
        //     });
        if (Id === '') {
            Id = '0';
        }
        return this.http.get<Blob>(this.url + 'ExportFlexiBenefit/' + StartDate + '/' + EndDate + '/' + Status + '/' + Id, { responseType: 'blob' as 'json' })
            .map(fbfile => {
                return fbfile;
            });
    }

    UpdateFlexiBenefit(formData: FormData): Observable<any> {
        var objectToSend = JSON.stringify(formData);

        //let headerOptions = new HttpHeaders({ 'Content-Type': 'false' });

        return this.http.post<any>(this.url + 'UpdateFlexiBenefit', formData)
            .map(data => {
                return data;
            });
    }

    saveFlexiBenefit(formData: FormData): Observable<any> {
        var objectToSend = JSON.stringify(formData);

        //let headerOptions = new HttpHeaders({ 'Content-Type': 'false' });

        return this.http.post<any>(this.url + 'SaveFlexiBenefit', formData)
            .map(data => {
                return data;
            });
    }
}