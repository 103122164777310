﻿export class FragmencesModel {
    fragmences: any = [];
    merchandiseCategory: any;
    subCategory: any;
    category: any;
    brand: any;

    constructor() { }
    GetDDData() {

        return  { merchandiseCategory: this.merchandiseCategory, category: this.category, subCategory: this.subCategory, brand: this.brand};
    }


}
