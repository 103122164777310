﻿import { Injectable } from '@angular/core';

import { User } from '../_models/index';

@Injectable()
export class UserService {
    user: User;
    constructor() { }

    setUser(userInfo: User) {
        this.user = userInfo;
    }

    getUser() {
        return this.user;
    }


}
