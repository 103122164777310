﻿import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthenticationService, UserService} from '../_services/index';
import {User} from '../_models/user'

@Injectable()
export class AuthGuard implements CanActivate {
    currentUser:User;
    constructor(private router: Router,private authenticationService:AuthenticationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('currentUser')) {
            this.currentUser=JSON.parse(localStorage.getItem('currentUser'));
            this.authenticationService.login(this.currentUser.UserName,this.currentUser.Password);
           
            return true;
        }
        console.log(state.url);
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}