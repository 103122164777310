﻿import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import '../assets/app.css';
import { UserIdleService } from 'angular-user-idle';
import { AuthenticationService } from './_services/index';

@Component({
    moduleId: module.id.toString(),
    selector: 'app',
    templateUrl: 'app.component.html?v=${new Date().getTime()}'
})

export class AppComponent {
    constructor(private router: Router, private userIdle: UserIdleService, private authenticationService: AuthenticationService) {
    }

    ngOnInit() {
        //Start watching for user inactivity.
        this.userIdle.startWatching();

        // Start watching when user idle is starting.
        //this.userIdle.onTimerStart().subscribe(count => console.log(count));

        // Start watching when user idle is starting and reset if user action is there.
        this.userIdle.onTimerStart().subscribe(count => {
            var eventList = ["click", "mouseover", "keydown", "DOMMouseScroll", "mousewheel",
            "mousedown", "touchstart", "touchmove", "scroll", "keyup"];
            for (let event of eventList) {
                document.body.addEventListener(event, () => this.userIdle.resetTimer());
            }
        });
        
        // Start watch when time is up.
        this.userIdle.onTimeout().subscribe(() => {
            this.authenticationService.logout();
            this.router.navigate(['login']);
        })
    }
}