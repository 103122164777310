﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { OrdersModel } from '../_models/orders';
import { User } from '../_models/user';


@Injectable()
export class OrdersService {
    /****** mini.luxasia.com ******/
    //PROD URL
    //url = 'http://116.12.52.170/plesk-site-preview/api.complimentarygoods.luxasia.com/api/cmg/'; 

    //UAT URL
    //url = 'http://116.12.52.170/plesk-site-preview/uatcmgapi.complimentarygoods.luxasia.com/api/cmg/';

    /****** plesk.luxasia.com ******/
    //PROD URL
    url = 'https://plesk.luxasia.com/plesk-site-preview/api.complimentarygoods.luxasia.com/api/cmg/';

    //UAT URL
    //url = 'http://115.42.209.163/plesk-site-preview/uatcmgapi.complimentarygoods.luxasia.com/api/cmg/';

    // url = 'http://localhost:9287/api/cmg/'; /* local URL*/ 

    currentUser: User;

    constructor(private http: HttpClient) {
    }

    getOrders(customerId: number, ordertype: string) {

        return this.http.get<any>(this.url + 'GetCustomerOrders/' + customerId + '/' + ordertype)
            .map(orders => {
                //console.log(orders);
                return orders;
            });
    }

    getFragmencesFilter() {

        return this.http.get<any>(this.url + 'GetAllCategoriesMasters')
            .map(fragmences => {
                return fragmences;
            });
    }

    getCategoriesFilter(userName: string, countryCode: string) {

        return this.http.get<any>(this.url + 'GetAllCategories/' + userName + '/' + countryCode)
            .map(categories => {
                return categories;
            });
    }

    getCustomerCountry(username: string) {

        return this.http.get<any>(this.url + 'GetCustomerCountry/' + username)
            .map(categories => {
                return categories;
            });
    }

    getCustomerCountryCurrency(username: string) {

        return this.http.get<any>(this.url + 'getCustomerCountryCurrency/' + username)
            .map(categories => {
                return categories;
            });
    }

    getFragranceByCodeCountry(code: string, countryCode: string) {
        console.log("****" + code + " / " + countryCode);

        return this.http.get<any>(this.url + 'GetFragranceByCodeCountry/' + code + '/' + countryCode)
            .map(Fragrance => {
                console.log("****" + Fragrance);
                return Fragrance;
            });
    }

    getSubCategoriesFilter(param: string, userName: string, countryCode: string) {

        return this.http.get<any>(this.url + 'GetAllSubCategoriesfilter/' + param + '/' + userName + '/' + countryCode)
            .map(subCategories => {
                return subCategories;
            });
    }

    getSKUSearch(param: string, userName: string, countryCode: string) {
        //[Route("api/cmg/GetAllFragrancesBySearchString/{searchstring}")]
        return this.http.get<any>(this.url + 'GetAllFragrancesBySearchString/' + param + '/' + userName + '/' + countryCode)
            .map(data => {
                return data;
            });
    }

    deleteDraftOrder(orderId: any) {
        return this.http.get<any>(this.url + 'DeleteDraftOrder/' + orderId)
            .map(data => {
                return data;
            });
    }


    deleteInProgressOrder(orderinfo: any): Observable<any> {
        var objectToSend = JSON.stringify(orderinfo);

        let headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

        console.log(objectToSend);
        return this.http.post<any>(this.url + 'DeleteInProgressOrder', objectToSend, {
            headers: headerOptions
        })
            .map(data => {
                return data;
            });
    }


    getPriceRangeSearch(param: any, userName: string, countryCode: string) {
        //[Route("api/cmg/GetAllFragrancesByPriceRange/{from}/{to}")]
        return this.http.get<any>(this.url + 'GetAllFragrancesByPriceRange/' + param.min + '/' + param.max + '/' + userName + '/' + countryCode)
            .map(data => {
                return data;
            });
    }
    getDraftOrder(custId: number, oType: string) {
        return this.http.get<any>(this.url + 'GetCustomerDraftOrder/' + custId + '/' + oType)
            .map(data => {
                return data;
            });
    }
    getMechantiseCategoriesFilter(param1: string, param2: string, userName: string, countryCode: string) {

        return this.http.get<any>(this.url + 'GetAllMerchndiseCategoryfilter/' + param1 + '/' + param2 + "/" + userName + '/' + countryCode)
            .map(mechantiseCategories => {
                return mechantiseCategories;
            });
    }
    getBrandFilter(param1: string, param2: string, param3: string, userName: string, countryCode: string) {

        return this.http.get<any>(this.url + 'GetAllBrandsfilter/' + param1 + '/' + param2 + '/' + param3 + '/' + userName + '/' + countryCode)
            .map(brand => {
                return brand;
            });
    }

    getFilterBy(params: any, countryCode: string) {

        // tslint:disable-next-line:max-line-length
        return this.http.get<any>(this.url + 'GetAllFragrancesByfilters/' + params.merchandiseCategory + '/' + params.category + '/' + params.subCategory + '/' + params.brand + '/' + countryCode)
            .map(data => {
                return data;
            });
    }

    /*
    placeOrder(orderinfo: any): Observable<any> {
        var objectToSend = JSON.stringify(orderinfo);

        let headerOptions = new HttpHeaders({ 'Content-Type': 'application/json' });

        console.log(objectToSend);
        return this.http.post<any>(this.url + 'PlaceOrder', objectToSend, {
            headers: headerOptions
        }).map(data => {
            return data;
        });
    }
    */
    placeOrder(formData: FormData): Observable<any> {
        return this.http.post<any>(this.url + 'PlaceOrder', formData)
            .map(data => {
                return data;
            });
    }

    UpdateUserEmail(email: string, customerid: number) {

        return this.http.get<any>(this.url + 'UpdateUserEmail/' + email + '/' + customerid)
            .map(data => {
                return data;
            });

    }
    UpdateUserPhone(phone: string, customerid: number) {

        return this.http.get<any>(this.url + 'UpdateUserPhone/' + phone + '/' + customerid)
            .map(data => {
                return data;
            });

    }

    SubscribeMarket(marketFlg: String, phone: String, email: String, customerId: string) {
        return this.http.get<any>(this.url + 'MarketConsent/' + customerId + '/' + marketFlg + '/' + email + '/' + phone + '/')
            .map(data => {
                return data;
            });
    }

    storeLocatorVisibleCheck(username: string) {
        return this.http.get<any>(this.url + 'StoreLocatorVisibleCheck/' + username)
            .map(result => {
                return result;
            });
    }

    getStores(username: string, countryCode: string) {
        return this.http.get<any>(this.url + 'GetStores/' + username + "/" + countryCode)
            .map(stores => {
                return stores;
            });
    }

    //getUniqueBrandsFilter() {
    //    return this.http.get<any>(this.url + 'GetAllBrands')
    //        .map(brands => {
    //            return brands;
    //        });
    //}

    getUniqueBrandsByCountryCode(userName, countryCode) {

        return this.http.get<any>(this.url + 'GetAllBrandsByCountryCode/' + userName + '/' + countryCode)
            .map(brands => {
                return brands;
            });
    }


    getActiveFragrancesByCountry(countryCode) {
        console.log("getActiveFragrancesByCountry : " + countryCode);
        return this.http.get<any>(this.url + 'GetActiveFragrancesByCountry/' + countryCode)
            .map(activeFragrances => {
                return activeFragrances;
            });
    }


    getAllFragrancesByBrand(brand, countryCode) {
        //[Route("api/cmg/GetAllFragrancesByBrand/{brand}")]
        return this.http.get<any>(this.url + 'GetAllFragrancesByBrand/' + brand + "/" + countryCode)
            .map(data => {
                return data;
            });
    }
}

