import { Component, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html?v=${new Date().getTime()}',
  styleUrls: ['./modal.component.css?v=${new Date().getTime()}']
})
export class ModalComponent {

  fbrecord;

  constructor(private dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any) {
      this.fbrecord = data.fbrecord
     }

     @HostListener('document:keyup.escape') onClose() {
      this.onCancel();
    }
  
    onCancel() {
      this.dialogRef.close();
    }
  
    onSubmit() {
      this.dialogRef.close(this.fbrecord);
    }

}
