export class FlexiBenefits {
    // Id: number;
    // ReceiptDate: string;
    // Category: string;
    // NTA: number;
    // TA: number;
    // FileName: string;
    // Status: string;
    // CustId: number;
    // Attachment: any
    fb: any = [];
    ClaimEntitlementValue: number=0;
    ClaimUtilisationValue: number=0;
    AllowedFlexiBenefitLimit :number =0;
    IsAdmin:boolean= false;
    CreateRequestAllowed:boolean= false;
    newFBList: any = [
        {Id: 0,
        ReceiptDate :'',
        Category: '',
        NTA: 0.00,
        TA: 0.00,
        NTAFlag: true,
        TAFlag: true,
        FileName: '',
        Status: '',
        CustId: '',
        Attachment: File},
        {
            Id: 0,
        ReceiptDate :'',
        Category: '',
        NTA: 0.00,
        TA: 0.00,
        NTAFlag: true,
        TAFlag: true,
        FileName: '',
        Status: '',
        CustId: '',
        Attachment: File
        },
        {
            Id: 0,
        ReceiptDate :'',
        Category: '',
        NTA: 0.00,
        TA: 0.00,
        NTAFlag: true,
        TAFlag: true,
        FileName: '',
        Status: '',
        CustId: '',
        Attachment: File
        }
    ];
    CategoryDropDown: any = [];
    CategoryDropDownSG: any = [
        {
            CategoryName: 'Childcare Subsidy Non-Taxable',
            NTAFlag: true,
            TAFlag: false
        },
        {
            CategoryName: 'Childcare Subsidy Taxable',
            NTAFlag: false,
            TAFlag: true
        },
        {
            CategoryName: 'Dental',
            NTAFlag: true,
            TAFlag: false
        },
        {
            CategoryName: 'Health Screening',
            NTAFlag: true,
            TAFlag: false
        },
        {
            CategoryName: 'Maternity Expenses',
            NTAFlag: true,
            TAFlag: false
        },
        {
            CategoryName: 'Outpatient Clinical & Specialist',
            NTAFlag: true,
            TAFlag: false
        },
        {
            CategoryName: 'Traditional Chinese Medicine',
            NTAFlag: true,
            TAFlag: false
        },
        {
            CategoryName: 'Holiday Trip',
            NTAFlag: false,
            TAFlag: true
        },
        {
            CategoryName: 'Insurance Coverage',
            NTAFlag: false,
            TAFlag: true
        },
        {
            CategoryName: 'IT-related Equipment',
            NTAFlag: false,
            TAFlag: true
        },
        {
            CategoryName: 'Membership',
            NTAFlag: false,
            TAFlag: true
        },
        {
            CategoryName: 'Optical / Vision',
            NTAFlag: false,
            TAFlag: true
        },
        {
            CategoryName: 'Personal Development & Enrichment',
            NTAFlag: false,
            TAFlag: true
        },
        {
            CategoryName: 'Personal Image',
            NTAFlag: false,
            TAFlag: true
        },
        {
            CategoryName: 'Professional Subscriptions',
            NTAFlag: false,
            TAFlag: true
        },
        {
            CategoryName: 'Recreation Activities',
            NTAFlag: false,
            TAFlag: true
        },
        {
            CategoryName: 'Wellness Package',
            NTAFlag: false,
            TAFlag: true
        }
    ];
    CategoryDropDownMY: any = [
        {
            CategoryName: 'Dental',
            NTAFlag: true,
            TAFlag: false
        },
        {
            CategoryName: 'Maternity Expenses',
            NTAFlag: true,
            TAFlag: false
        },
        {
            CategoryName: 'Traditional Chinese Medicine',
            NTAFlag: true,
            TAFlag: false
        },
        {
            CategoryName: 'Holiday Trip',
            NTAFlag: false,
            TAFlag: true
        },
        {
            CategoryName: 'Insurance Coverage',
            NTAFlag: false,
            TAFlag: true
        },
        {
            CategoryName: 'Internet Subscriptions',
            NTAFlag: false,
            TAFlag: true
        },
        {
            CategoryName: 'IT-related Equipment',
            NTAFlag: false,
            TAFlag: true
        },
        {
            CategoryName: 'Membership',
            NTAFlag: false,
            TAFlag: true
        },
        {
            CategoryName: 'Optical / Vision',
            NTAFlag: true,
            TAFlag: false
        },
        {
            CategoryName: 'Personal Image',
            NTAFlag: false,
            TAFlag: true
        },
        {
            CategoryName: 'Professional Subscriptions',
            NTAFlag: false,
            TAFlag: true
        },
        {
            CategoryName: 'Recreation Activities',
            NTAFlag: false,
            TAFlag: true
        },
        {
            CategoryName: 'Wellness Package',
            NTAFlag: false,
            TAFlag: true
        }
    ];
    draft: any = [];
    done: any = [];
    done2: any = [];
    adminFBList: any = [];
    draftNtaTotal : number;
    draftTaTotal : number;
    draftGrandTotal : number;
    doneNtaTotal :number;
    doneTaTotal : number;
    doneGrandTotal :number;
    doneAdminNtaTotal = 0;
    doneAdminTaTotal = 0;
    doneAdminGrandTotal = 0;
    constructor() { }

    // initializeNewFB() {
    //     for (let i = 0; i < 10; i++) {
    //         let newItem = { ReceiptDate: new Date(), Category: "", NTA:0,TA:0,Attachment:null }; // Object.assign({}, item);
    //         this.newFB.push(newItem);
    //     }
    // }
}
export class NewFB {
    Id: number;
    ReceiptDate :Date;
    Category: string;
    NTA: number;
    TA: number;
    FileName: string;
    Status: string;
    CustId: number;
    Attachment: any;
}

// type NewFB = Array<{ Id: number;
//     ReceiptDate :Date;
//     Category: string;
//     NTA: number;
//     TA: number;
//     FileName: string;
//     Status: string;
//     CustId: number;
//     Attachment: any; }>;