﻿import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AlertService } from '../_services/index';
import { AuthenticationService } from '../_services/authentication.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    moduleId: module.id.toString(),
    templateUrl: 'forgotPassword.component.html?v=${new Date().getTime()}'
})

export class ForgotPasswordComponent {
    model: any = {};
    loading = false;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private toastr: ToastrService) { }

    forgotPassword() {
        this.loading = true;
        this.authenticationService.forgot(this.model.key, this.model.newPassword, this.model.cnfPassword)
            .subscribe(
            data => {
                //this.alertService.success('Password has been Reset, pls check your email to activate/update.', true);
                this.toastr.success('Password has been Reset, pls check your email to activate / update.', 'Success');
                this.router.navigate(['/login']);
            },
            error => {
                //this.alertService.error(error.message);
                this.toastr.error(error, 'Error');
                this.loading = false;
            });
    }
}
