﻿import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AlertService } from '../_services/index';
import { AuthenticationService } from '../_services/authentication.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    moduleId: module.id.toString(),
    templateUrl: 'register.component.html?v=${new Date().getTime()}'
})

export class RegisterComponent {
    model: any = {};
    loading = false;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private toastr: ToastrService) { }

    resetPassword() {
        this.loading = true;
        /* this.authenticationService.resetPassword(this.model.email, this.model.username)
            .subscribe(
            data => {
                //this.alertService.success('Password Reset Successful', true);
                this.toastr.success('Password Reset Successful. New password has been sent to registered email address.','Success');
                this.router.navigate(['/login']);
            },
            error => {
                //this.alertService.error(error.message);
                this.toastr.error(error.message,'Error');
                this.loading = false;
            }); */

        this.authenticationService.resetPassword(this.model.username)
            .subscribe(
            data => {
                //this.alertService.success('Password Reset Successful', true);
                if (data) {
                    this.toastr.success('Password Reset Successful. New password has been sent to registered email address.','Success');
                    this.router.navigate(['/login']);
                } else {
                    this.toastr.error('Password Reset Unsuccessful. Please contact Administrator.','Error');
                    this.router.navigate(['/login']);
                }    
            },
            error => {
                //this.alertService.error(error.message);
                this.toastr.error(error.message,'Error');
                this.loading = false;
            });
    }
}
