﻿import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AlertService, AuthenticationService } from '../_services/index';
import { ToastrService } from 'ngx-toastr';

@Component({
    moduleId: module.id.toString(),
    templateUrl: 'login.component.html?v=${new Date().getTime()}'
})

export class LoginComponent implements OnInit {
    model: any = {};
    loading = false;
    returnUrl: string;
    TermsDisplay: any = 'none';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private toastr: ToastrService) {
    }

    ngOnInit() {
        console.log("ngOnInit()");

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        let downUrl = "appdown";

        //navigate to down page
        //this.authenticationService.ValidateDownTime()
        //this.authenticationService.ValidateBatchProcessingDownTime()
        //    .subscribe(
        //        data => {
        //            if (data === true) {
        //               localStorage.clear();
        //               this.authenticationService.logout();
        //               this.router.navigate([downUrl]);
        //            }
        //        }
        //);
    }

    onTermsChange(value: any) {
        this.authenticationService.TermsChange(value)
            .subscribe(
                data => {
                    if (this.model.Terms === false) {
                        this.TermsDisplay = 'inline';
                    }
                }
            );
    }

    authenticationError() {
        this.toastr.error('Authentication failed, Invalid credentials.', 'Error');
    }

    login() {
        this.loading = true;
        this.authenticationService.login(this.model.username, this.model.password)
            .subscribe(
                data => {
                    if (data === null) {

                        //this.alertService.error('Invalid username/password');
                        this.authenticationError();
                        this.loading = false;
                    }
                    else {
                        if (data.Terms === false) {
                            this.TermsDisplay = 'inline';
                            this.loading = false;
                        }
                        else {
                            this.TermsDisplay = 'none';
                            this.router.navigate([this.returnUrl]);
                            this.loading = false;
                        }


                    }
                },
                error => {
                    //this.alertService.error(error);
                    this.toastr.error(error, 'Error');
                    this.loading = false;
                });


    }
}
