﻿export class OrdersModel {
    orderMasterId = 0;
    draftOrderMasterId = 0;
    selectedDraft: any = { 'orders': { '$values': [] } };
    selectedDone: any = { 'orders': { '$values': [] } };
    orders: any = [];
    qtys: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    types = [
        new Types('C', 'Complimentary'),
        /* new Types('N', 'Staff Purchase'),  -- commented for the future version*/
    ];
    savedOrder: any = [];
    type = new Types('C', 'Complimentary');
    qty: number;
    totalOrderQty = 0;
    ordergracevalue = 0;
    totalOrderColor = '';
    totalOrderPrice = 0;
    orderinfo: any;
    newOrders: any = [];
    todayDate = Date.now();
    sku: any = [];
    inprogress: any = [];
    inprogressItems = false;
    SKUSearch = "";
    UniqueBrand: any;
    ListOfBrands: any = [];
    StoreLocatorVisible = false;
    SelectedStore: any;
    ListOfStores: any = [];
    selectedInprogress: any = { 'orders': { '$values': [] } };
    draft: any = [];
    done: any = [];
    priceRange: any = {};
    ranges: any = [new Types('0', 'Please select'), new Types('1', '0-10'),
    new Types('2', '11-20'),
    new Types('3', '21-50'),
    new Types('4', '51-100'),
    new Types('5', '101-500'),
    new Types('6', '501-1000'),
    new Types('7', '1000-99999')];
    range = new Types('0', 'Please select');
    editInProgressAmt = 0;
    getActiveFragrances: any = [];

    constructor() { }

    devideOrders() {
        this.inprogress = this.orders.filter(item => item.OrderStatus === 'In Progress');
        if (this.inprogress.length > 0) {
            this.inprogressItems = true;
            this.selectInprogressOrder(this.inprogress[0].OrderMasterId);
        } else {
            this.selectedInprogress = { 'orders': { '$values': [] } };
        }

        this.draft = this.orders.filter(item => item.OrderStatus === 'Draft');
        if (this.draft.length > 0) {
            this.selectDraftOrder(this.draft[0].OrderMasterId);
        } else {
            this.selectedDraft = { 'orders': { '$values': [] } };
        }

        this.done = this.orders.filter(item => item.OrderStatus === 'Done');
        if (this.done.length > 0) {
            this.selectDoneOrder(this.done[0].OrderMasterId);
        } else {
            this.selectedDone = { 'orders': { '$values': [] } };
        }
    }
    selectInprogressOrder(i: number) {
        this.selectedInprogress = this.inprogress.filter(item => item.OrderMasterId === i)[0];
        if (this.ListOfStores.length > 0 && this.selectedInprogress.StoreCode) {
            var store = this.ListOfStores.filter(item => item.m_Item1 == this.selectedInprogress.StoreCode)[0];
            if (store) {
                this.selectedInprogress.StoreName = store.m_Item2;
            }
        }
    }


    selectDraftOrder(i: number) {
        this.selectedDraft = this.draft.filter(item => item.OrderMasterId === i)[0];
        this.orderMasterId = i;
        this.draftOrderMasterId = i;
        this.totalOrderQty = 0;
        this.totalOrderPrice = 0;
        this.newOrders = [];
        if (this.selectedDraft != undefined) {
            this.SelectedStore = this.StoreLocatorVisible && this.selectedDraft.StoreCode ? this.selectedDraft.StoreCode : 0;
            for (const item of this.selectedDraft.orders.$values) {
                item.totalPrice = item.OrderQty * item.RetailPrice;
                item.totalPrice = parseFloat(item.totalPrice.toFixed(2));
                let newItem = Object.assign({}, item);
                this.newOrders.push(newItem);
                this.totalOrderQty = this.totalOrderQty + item.OrderQty;
                this.totalOrderPrice = this.totalOrderPrice + item.totalPrice;

            }
            this.newOrders.Status = "Draft";
            this.totalOrderPrice = parseFloat(this.totalOrderPrice.toFixed(2));
        }

        //  this.newOrders= this.selectedDraft.orders.$values;

    }


    selectDoneOrder(i: number) {
        this.selectedDone = this.done.filter(item => item.OrderMasterId === i)[0];
        if (this.ListOfStores.length > 0 && this.selectedDone.StoreCode) {
            var store = this.ListOfStores.filter(item => item.m_Item1 == this.selectedDone.StoreCode)[0];
            if(store){
                this.selectedDone.StoreName = store.m_Item2;
            }
        }
    }

    addSKU(articleCode: number, totalBonus: number, type: string) {
        if (this.newOrders.filter(item => item.ArticleCode === articleCode).length === 0) {
            this.newOrders.push(this.sku.filter(item => item.ArticleCode === articleCode)[0]);
            if (!this.newOrders.Status) {
                this.newOrders.Status = "New"
            }
            this.getTotalQty(totalBonus, type);
        }
    }

    removeOrders(index: number, totalBonus: number, type: string) {
        this.newOrders.splice(index, 1);
        if (this.newOrders.length === 0) {
            //this.newOrders.Status = null;
        }
        this.getTotalQty(totalBonus, type);
    }

    clearCurrentOrder() {
        this.newOrders = [];
        this.totalOrderQty = 0;
        this.totalOrderPrice = 0;
        this.orderMasterId = 0;
        this.draftOrderMasterId = 0;
        this.editInProgressAmt = 0;
        this.SelectedStore = 0;
    }

    getTotalQty(totalBonus, type) {
        this.totalOrderQty = 0;
        this.totalOrderPrice = 0;
        this.totalOrderColor = '';
        for (const item of this.newOrders) {
            this.totalOrderQty = this.totalOrderQty + item.OrderQty;
            this.totalOrderPrice = this.totalOrderPrice + item.totalPrice;
        }
        this.totalOrderPrice = parseFloat(this.totalOrderPrice.toFixed(2))
        var mydate = new Date();

        if (mydate.getMonth() == 9 || mydate.getMonth() == 10 || mydate.getMonth() == 11)
            this.ordergracevalue = 10;
        
        
        if(totalBonus <= 0) {
            console.log("getTotalQty : " + totalBonus + "/" + type + "/" + (this.totalOrderPrice - this.editInProgressAmt) + "/" + totalBonus);
            
            if (this.totalOrderPrice - this.editInProgressAmt === 0) {
                this.totalOrderColor = '';
            } else if ((this.totalOrderPrice - this.editInProgressAmt) > totalBonus && type === 'C') {
                this.totalOrderColor = 'red';
            }
        }
        
        if(totalBonus > 0) {
            console.log("getTotalQty : " + totalBonus + "/" + type + "/" + (this.totalOrderPrice - this.editInProgressAmt) + "/" + (totalBonus + this.ordergracevalue));
        
            if ((this.totalOrderPrice - this.editInProgressAmt) > (totalBonus + this.ordergracevalue) && type === 'C') {
                this.totalOrderColor = 'red';
            }
        }
    }

    onQtyChange(index: number, totalBonus: number, type: string) {
        this.newOrders[index].totalPrice = this.newOrders[index].OrderQty * this.newOrders[index].RetailPrice;
        this.newOrders[index].totalPrice = parseFloat(this.newOrders[index].totalPrice.toFixed(2));
        this.getTotalQty(totalBonus, type);
    }

    editCompletedOrder(i: number, currentUser) {
        // this.selectedDraft.orders.$values = { 'orders': { '$values': [] } };
        let copiedRecord = this.done.filter(item => item.OrderMasterId === i)[0];
        this.orderMasterId = 0;
        this.newOrders = [];
        this.totalOrderQty = 0;
        this.totalOrderPrice = 0;
        this.SelectedStore = this.StoreLocatorVisible && copiedRecord.StoreCode ? copiedRecord.StoreCode : 0;
        for (const item of copiedRecord.orders.$values) {
            item.totalPrice = item.OrderQty * item.RetailPrice;
            item.totalPrice = parseFloat(item.totalPrice.toFixed(2));
            item.OrderMasterId = 0;
            let newItem = Object.assign({}, item);
            this.newOrders.push(newItem);
            this.totalOrderQty = this.totalOrderQty + item.OrderQty;
            this.totalOrderPrice = this.totalOrderPrice + item.totalPrice;
        }
        this.newOrders.Status = "Copy";
        this.totalOrderPrice = parseFloat(this.totalOrderPrice.toFixed(2));
        this.totalOrderColor = '';
        let totalBonus = currentUser.ComplimentaryGoodsValue + currentUser.BonusValue;
        
        console.log("editCompletedOrder : " + this.totalOrderPrice + "/" + totalBonus + "/" + this.type.id);
        if ((this.totalOrderPrice > totalBonus) && this.type.id === 'C') {
            this.totalOrderColor = 'red';
        }
    }

    editInProgressOrder(i: number) {
        //this.selectedDraft.orders.$values = { 'orders': { '$values': [] } };
        let copiedRecord = this.inprogress.filter(item => item.OrderMasterId === i)[0];
        this.totalOrderQty = 0;
        this.totalOrderPrice = 0;
        this.SelectedStore = 0;
        this.newOrders = [];
        this.orderMasterId = i;
        this.totalOrderColor = '';
        this.SelectedStore = this.StoreLocatorVisible && copiedRecord.StoreCode ? copiedRecord.StoreCode : 0;
        for (const item of copiedRecord.orders.$values) {
            item.totalPrice = item.OrderQty * item.RetailPrice;
            item.totalPrice = parseFloat(item.totalPrice.toFixed(2));
            let newItem = Object.assign({}, item);
            this.newOrders.push(newItem);
            this.totalOrderQty = this.totalOrderQty + item.OrderQty;
            this.totalOrderPrice = this.totalOrderPrice + item.totalPrice;
        }
        this.newOrders.Status = "In Progress";
        this.totalOrderPrice = parseFloat(this.totalOrderPrice.toFixed(2));
        this.editInProgressAmt = this.totalOrderPrice;

    }

    deleteInProgressOrder(i: number) {
        this.totalOrderPrice = 0;
        this.orderMasterId = i;
        let deleteRecord = this.inprogress.filter(item => item.OrderMasterId === i)[0];
        //console.log(deleteRecord);
        for (const item of deleteRecord.orders.$values) {
            item.totalPrice = item.OrderQty * item.RetailPrice;
            item.totalPrice = parseFloat(item.totalPrice.toFixed(2));
            this.totalOrderPrice = this.totalOrderPrice + item.totalPrice;
        }
        this.totalOrderPrice = parseFloat(this.totalOrderPrice.toFixed(2));
        //console.log(this.totalOrderPrice);
    }
}

export class Types {
    constructor(public id: string, public name: string) { }
}




